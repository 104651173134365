<template>
	<el-row class="">
		<!-- left_common -->
		<el-row class="mywallet" style="">
			<!-- left_common -->
			<el-col :span="6">
				<contentLeft type="5" style='box-shadow: 0px 0px 6px #c4c4c4;'></contentLeft>
			</el-col>
			<el-col :span="18">
				<el-row class="content-right">

					<el-row class="con-base" type="flex" justify="space-between">
						<el-col :span="10"><b><span style="color: #c43e1b;">|</span>我的通知</b></el-col>
						<el-col :span="8">
							<el-row :gutter="24">
								<el-col :span="6" style="position: relative">
									<el-button  style="color: white; background-color:#c43e1b ;" size="mini" @click="activity2(1)">活动通知</el-button>
									<span v-show="hdis_show==1" style="display: inline-block;margin-left:15px;border-radius: 50%;position: absolute; top: 30px;right: -6px;background: red;width: 8px;height: 8px;display:inline-block;"></span>
								</el-col>
								<el-col :span="6">
									<el-button style="color: white; background-color:rgba(142,142,142) ;" size="mini" @click="activity2(2)">状态通知</el-button>
									<span v-show="ztis_show==1" style="display: inline-block;margin-left:15px;border-radius: 50%;position: absolute; top: 30px;right: 164px;background: red;width: 8px;height: 8px;display:inline-block;"></span>
								</el-col>
								<el-col :span="8">
									<el-button style="color: white; background-color:rgba(142,142,142) ;" size="mini" @click="MarkRead">全部标记为已读</el-button>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-dialog :title="name" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
						<span>{{content}}</span>
						<span slot="footer" class="dialog-footer">
							<el-button @click="dialogVisible = false">取 消</el-button>
							<el-button type="primary" @click="queding">确 定</el-button>
						</span>
					</el-dialog>
					<el-row style="margin-left: 50px">
						<el-col v-show="is_show==1" v-for="itme in activity" style="border-bottom: 1px solid  #efeff0;height: 50px;line-height: 50px;color: #000000;position: relative;">
							<el-col style="padding-right: 50px;text-indent: 24px;">
								<span style="width: 70%;cursor: pointer;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;font-size: 14px;" @click="eject(itme.id)">{{itme.name}}</span>
								<span v-if="itme.state==0" style="display: inline-block;margin-left:15px;border-radius: 50%;position: absolute; top: 5px;background: red;width: 8px;height: 8px;display:inline-block;"></span>
								<span style="display: inline-block;float: right; font-size: 14px;">{{itme.create_time}}</span></el-col>
						</el-col>
						<el-col v-show="is_show==2" v-for="itme in activity1" style="border-bottom: 1px solid  #efeff0;height: 50px;line-height: 50px;color: #000000;position: relative;">
							<el-col style="padding-right: 50px;text-indent: 24px;"><span style="width: 70%;cursor: pointer;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: inline-block;font-size: 14px;"
								 @click="eject(itme.id)">{{itme.name}}</span><span v-if="itme.state==0" style="display: inline-block;margin-left:15px;border-radius: 50%;position: absolute; top: 5px;background: red;width: 8px;height: 8px;display:inline-block;"></span>
								<span style="display: inline-block;float: right;font-size: 14px;">{{itme.create_time}}</span></el-col>
						</el-col>
					</el-row>
				</el-row>
			</el-col>
		</el-row>
		<!--right_content-->
	</el-row>
</template>

<!--逻辑-->
<script>
	import contentLeft from '@/components/contentLeft.vue';
	import {
		requestPurchase,
		requestRecords
	} from "../api/request";
	import {
		requestMessage_list,
		requestMessage_details,
		read_all
	} from "../api/request";

	export default {
		name: 'mywallet',
		components: {
			contentLeft
		},
		data() {
			return {
				choice: 1,
				activity: [],
				activity1: [],
				dialogVisible: false,
				content: "",
				name: "",
				//判断
				is_show: 1,
				//活动通知判断
				hdis_show: 0,
				//状态通知判断
				ztis_show: 0,
			}
		},
		methods: {
			// 全部标记为已读
			MarkRead(){
				read_all().then(res => {
					console.log(res)
					if(res.code == 0){
						this.activity1_1(1)
						this.activity1_1(2)
					}
				})
			},
			//获取数据
			activity1_1(e) {

				requestMessage_list(e).then(res => {
					if (e == 1) {
						this.activity = res.ret;
						console.log(res)
						for (var i = 0; i < res.ret.length; i++) {
							if (res.ret[i].state == 0) {
								this.hdis_show = 1;
								break
							} else {
								this.hdis_show = 2;
							}
						}
					} else if (e == 2) {
						this.activity1 = res.ret;
						for (var i = 0; i < res.ret.length; i++) {
							if (res.ret[i].state == 0) {
								this.ztis_show = 1;
								break
							} else {
								this.ztis_show = 2;
							}
						}
					}
				})
			},
			//查看详情完重新获取
			queding() {
				this.dialogVisible = false
				this.activity1_1(this.is_show)
			},
			//修改显示状态
			activity2(e) {
				this.is_show = e;
			},
			//打开弹窗
			handleClose(done) {
				// this.$confirm('确认关闭？')
				// 	.then(_ => {
				// 		done();
				// 	})
				// 	.catch(_ => {});
					this.dialogVisible = false
			},
			//查看详情
			eject(id) {
				console.log(id)
				this.dialogVisible = true;
				let _this = this;
				requestMessage_details(id).then(res => {
					_this.activity1_1(1);
					_this.activity1_1(2);
					this.content = res.ret[0].content;
					this.name = res.ret[0].name + "------时间：" + res.ret[0].create_time;
					for (let i = 0; i < _this.activity.length; i++) {
						if (id == _this.activity[i].id) {
							_this.activity[i].state = 1
						}
					}
				})
			}
		},
		mounted() {
			if (this.Account.GetSession() == null) {
				this.$router.push({
					path: "/"
				});
				return
			}
			this.activity1_1(1)
			this.activity1_1(2)
			// console.log(this.$route.query.open)
			if(this.$route.query.open){
				this.is_show=2
			}
		}

	}
</script>


<!--样式-->
<style scoped>
	.mywallet {
		width: 1350px;
		padding: 20px;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.content-right {
		box-shadow: 0px 0px 6px #c4c4c4;
		overflow: hidden;
	}

	.con-base {
		height: 80px;
		line-height: 80px;
		margin-left: 50px;
		border-bottom: 1px solid #efeff0;
		;
	}

	.con-base b span,
	.con-detail b span {
		color: #007aff;
		margin-right: 7px;
	}

	/**内容区域 */
	.after_screen {
		color: #007bff;
	}

	.row-record {
		margin-left: 50px;
	}

	.record {
		height: 80px;
		line-height: 60px;
		font-size: 12px;
		cursor: pointer;
	}
</style>
